@font-face {
  font-family: "SamsungOne";
  src: url("../../assets/fonts/SamsungOne-400_v2.0_20200508.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOne";
  src: url("../../assets/fonts/SamsungOne-500_v2.0_20200508.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOne";
  src: url("../../assets/fonts/SamsungOne-600C_v1.0.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOne";
  src: url("../../assets/fonts/SamsungOne-700C_v1.0.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOneKorean";
  src: url("../../assets/fonts/SamsungOneKorean-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOneKorean";
  src: url("../../assets/fonts/SamsungOneKorean-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SamsungSharpSans";
  font-weight: 400;
  src: url("../../assets/fonts/SamsungSharpSans-Regular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "SamsungSharpSans";
  font-weight: 500;
  src: url("../../assets/fonts/samsungsharpsans-medium.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "SamsungSharpSans";
  font-weight: 700;
  src: url("../../assets/fonts/SamsungSharpSansBd.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "iCielSamsungSharpSans";
  font-weight: 400;
  src: url("../../assets/fonts/iCielSamsungSharpSansRegular.otf")
    format("opentype");
}

@font-face {
  font-family: "iCielSamsungSharpSans";
  font-weight: 500;
  src: url("../../assets/fonts/iCielSamsungSharpSans-Medium.otf")
    format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "iCielSamsungSharpSans";
  font-weight: 700;
  src: url("../../assets/fonts/iCielSamsungSharpSans-Bold.otf")
    format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "CyrillicSamsungOne";
  unicode-range: U+0400-04FF;
  font-weight: 400;
  src: url("../../assets/fonts/SamsungOne-400_v2.0_20200508.ttf")
    format("truetype");
}

@font-face {
  font-family: "CyrillicSamsungOne";
  unicode-range: U+0400-04FF;
  font-weight: 500;
  src: url("../../assets/fonts/SamsungOne-500_v2.0_20200508.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "CyrillicSamsungOne";
  unicode-range: U+0400-04FF;
  font-weight: 700;
  src: url("../../assets/fonts/SamsungOne-700_v2.0_20200508.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "SamsungSharpSansRussia";
  font-weight: 700;
  src: url("../../assets/fonts/SamsungSharpSansRussia-Bold.otf")
    format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "SamsungOneRussia";
  src: url("../../assets/fonts/SamsungOneRussia-600C_v1.0.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOneRussia";
  src: url("../../assets/fonts/SamsungOneRussia-700C_v1.0.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SamsungOneChina";
  src: url("../../assets/fonts/SamsungOneChina.ttf") format("truetype");
}
